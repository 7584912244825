// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-series-jsx": () => import("./../../../src/pages/series.jsx" /* webpackChunkName: "component---src-pages-series-jsx" */),
  "component---src-pages-tags-jsx": () => import("./../../../src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/Post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-series-jsx": () => import("./../../../src/templates/Series.jsx" /* webpackChunkName: "component---src-templates-series-jsx" */)
}

